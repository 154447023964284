export const environment = {
  production: true,
  Source: "EKYCUI",
  API_ENDPOINT: "https://api.ekyc.engage-my.apac.bmw.cloud",
  BMW_Hostname: "ekyc.bmw.com.my",
  MINI_Hostname: "ekyc.mini.my",
  Motorrad_Hostname: "ekyc.bmw-motorrad.com.my",
  EngageBMW_URL: "https://engage.bmw.com.my/#/",
  EngageMini_URL: "https://engage.mini.my/#/",
  EngageMotorrad_URL: "https://engage.bmw-motorrad.com.my/#/",

  BMW_Hostname_Cloud: "bmw.ekyc.engage-my.apac.bmw.cloud",
  MINI_Hostname_Cloud: "mini.ekyc.engage-my.apac.bmw.cloud",
  Motorrad_Hostname_Cloud: "motorrad.ekyc.engage-my.apac.bmw.cloud",
  EngageBMW_Cloud_URL: "https://bmw.app.engage-my.apac.bmw.cloud/#/",
  EngageMini_Cloud_URL: "https://mini.app.engage-my.apac.bmw.cloud/#/",
  EngageMotorrad_Cloud_URL: "https://motorrad.app.engage-my.apac.bmw.cloud/#/",
  StaticFilePath: 'https://engage-my.apac.bmw.cloud/static',
  EnableChat: true,
  CCTChat: "https://cctapiapac.gc.azure.bmw.cloud/widget/bmw_apac_prod_my_web/script.js",
  LocRocketId: "fibuxy/ekyc",
  //Show Messages
  Message_ImageUploaded: 'Image uploaded successfully. Please proceed to answer your Security Questions.',
  Message_OCRUnsuccessful: 'Verification unsuccessful. Please retry.',
  Generic_Error_Message: 'We are unable to proceed with your application. Please contact customer interaction centre at +603-27192688 for further assistance.',
  PendingSQ_Request_Message: 'Please complete your security question verification via your mobile phone.',
  PendingOCRSQ_Message: 'Please upload your identification document and complete security question via your mobile phone.',
  SendSMS_Wait_Message: 'Please wait for 60 seconds before resending the SMS.'
};
